import * as React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import Layout from "../layout";
import { 
    TextField,
    Box,
    Button,
    FormControlLabel,
    Checkbox,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem
 } from '@mui/material';
 import InputMask from "react-input-mask";
import { getLockers, getSatuPaymentUrl } from '../services/payment.service';

class Intercity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: "",
                email: "",
                phone: "",
                locker: "",
                senderCity: 'Астана',
                dropTime: '',
                receiverName: '',
                receiverPhone: '',
                receiverCity: 'Алматы',
                receiverLocker: '',
                check: false,
                check1: false,
            },
            lockers: [],
            showMap: true,
            showMap2: true,
            show: false
        };
    }

    // getQueryParams = () => {
    //     const searchParams = new URLSearchParams(document.location.search)
    //     let order = searchParams.get('order')
    //     let name = searchParams.get('name')
    //     let email = searchParams.get('email')
    //     let phone = searchParams.get('phone')
    //     let locker = searchParams.get('locker')

    //     this.setState({
    //         ...this.state,
    //         form: {
    //             order: order !== null ? order : '',
    //             name: name !== null ? name : '',
    //             email: email !== null ? email : '',
    //             phone: phone !== null ? phone : '',
    //             locker: locker !== null ? locker : '',
    //             senderCity: 'Астана',
    //             dropTime: '',
    //             receiverName: '',
    //             receiverPhone: '',
    //             receiverCity: '',
    //             receiverLocker: '',
    //             check: false
    //         }
    //       });
    //   }

    componentDidMount() {
        // console.log()
        // const script = document.createElement("script");

        // script.src = "https://static.tastamat.kz/widget/script-1.1.0.js";
        // script.type = "text/javascript"

        // document.body.appendChild(script);

        getLockers()
            .then(res => {
                this.setState({
                    ...this.state,
                    lockers: res.data.list
                  });
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleChange = e => {
        let { name, value, checked } = e.target;

        if(name === 'senderCity') {
            this.state.form.receiverCity = value === 'Астана' ? 'Алматы' : 'Астана'
        }
        if(name === 'receiverCity') {
            this.state.form.senderCity = value === 'Астана' ? 'Алматы' : 'Астана'
        }

        this.setState(
            {
                form: {
                    ...this.state.form,
                    [name]: name !== 'checkbox' || name !== 'checkbox1' ? value : checked
                }
            },
        );

        console.log(this.state.form[name]);
    }

    setLocker = (e, region) => {

        if (region === '01') {
            this.setState({
                ...this.state,
                form: {
                    ...this.state.form,
                    locker: `Индекс ${e.index} - ${e.address.buildingShortRu}`
                },
                showMap: false,
            });
        } else if (region === '02') {
            this.setState({
                ...this.state,
                form: {
                    ...this.state.form,
                    receiverLocker: `Индекс ${e.index} - ${e.address.buildingShortRu}`
                },
                showMap2: false,
            });
        }
    }

    showMap = (region) => {
        if (region === '01') {
            this.setState({
                ...this.state,
                showMap: true,
            });
        } else if (region === '02') {
            this.setState({
                ...this.state,
                showMap2: true,
            });
        }
    }

    payment = () => {
        var phone = this.state.form.phone.replace(/[^0-9+]/g, '');
        var phone2 = this.state.form.receiverPhone.replace(/[^0-9+]/g, '');
        
        var d = new Date().getTime()

        let obj = {
            invoiceId: d,
            amount: 2000,
            recipientName: this.state.form.name,
            recipientEmail: this.state.form.email,
            recipientPhone: phone,
            fromApp: 'intercity',
            data: JSON.stringify({
                senderName: this.state.form.name,
                senderPhone: phone,
                senderCity: this.state.form.senderCity,
                senderLocker: this.state.form.locker,
                dropTime: this.state.form.dropTime,
                receiverName: this.state.form.receiverName,
                receiverPhone: phone2,
                receiverCity: this.state.form.receiverCity,
                receiverLocker: this.state.form.receiverLocker,
            })
        }

        getSatuPaymentUrl(obj)
            .then(res => {
                            
                console.log("SUCCESS");
                console.log(res.data);

                window.location.href = res.data.url;
            })
            .catch(err => {
                console.log(err)
            })
    }


    render() {
        return (
            <>
            <Layout>
            <YMaps>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '35ch' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: '30px'
                    }}
                    noValidate
                    autoComplete="on"
                >
                    <Typography variant="h4" sx={{
                        mt: '1ch',
                        mb: '1ch',

                    }}>
                        Отправитель
                    </Typography>
                    <TextField
                        required
                        label="Имя"
                        name='name'
                        value={this.state.form.name}
                        onChange={this.handleChange}

                    />
                    <TextField
                        required
                        label="Email"
                        name='email'
                        value={this.state.form.email}
                        onChange={this.handleChange}

                    />
                    <InputMask
                        required
                        mask="+7(799) 999 99 99"
                        value={this.state.form.phone}
                        disabled={false}
                        maskChar=" "
                        onChange={this.handleChange}
                    >
                        {() => <TextField
                        required
                        label="Номер телефона"
                        name='phone'
                        // value={this.state.form.phone}
                        
                    />}
                    </InputMask>

                    <FormControl
                    required
                     sx={{
                        m: 1,
                        width: '35ch'
                    }}>
                        <InputLabel id="senderCityLabel">Город</InputLabel>
                        <Select
                            required
                            labelId="senderCityLabel"
                            id="senderCity"
                            name="senderCity"
                            value={this.state.form.senderCity}
                            label="senderCity"
                            onChange={this.handleChange}
                            defaultValue={'Астана'}
                        >
                            <MenuItem value={'Астана'} defaultValue>Астана</MenuItem>
                            <MenuItem value={'Алматы'}>Алматы</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        required
                        label="Тастамат"
                        name='locker'
                        value={this.state.form.locker}
                        onChange={this.handleChange}
                    />

                    
                    {
                        this.state.showMap ?
                        <Map 
                            state={{ 
                                center:  this.state.form.senderCity === 'Астана' ? [51.128207,71.430411] : [43.248095, 76.913244 ], 
                                zoom: 11,
                            }} 
                            width='35ch'    
                            height='35ch' 
                        >
                            {this.state.lockers.map((item) => {
                                if(item.address.regionCode === (this.state.form.senderCity === 'Астана' ? '01' : '02')){
                                    var div = document.createElement("div");
                                    var btn = document.createElement("button");
                                    btn.className = "tstmtWdgt__choose";
                                    btn.type = "button";
                                    btn.innerText = "Выбрать";
                                    btn.id = item.id;
                                    div.className = "tstmtWdgt__ballon-body";
                                    div.innerHTML = '<p class="id">Индекс - ' + item.index + '</p>' +
                                    '<p class="title">тастамат</p>'+
                                    '<p class="address">' + item.address.buildingShortRu + '</p>'+
                                    '<p class="street">' + item.address.streetShortRu + ', ' + item.address.buildingNumber + '</p>'+
                                    '<p class="desc">' + (item.location.descriptionRu ? item.location.descriptionRu : '') + '</p>' +
                                    '<p class="desc">' + (item.location.time ? ('Режим работы: ' + item.location.time) : '') + '</p>'
                                    div.appendChild(btn);

                                    return (
                                        <Placemark
                                        options={{preset: 'islands#circleIcon', iconColor: '#fd7e14'} }
                                        defaultGeometry={[item.location.latitude, item.location.longitude]}
                                        key={item.id}
                                        modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                        properties={{
                                            iconCaption: item.index,
                                            hintContent: item.index,
                                            balloonContentBody: div.outerHTML,
                                        }}
                                        onClick={() => {
                                            let con = this
                                            setTimeout(function () {
                                                document.getElementById(item.id).addEventListener('click', () => {
                                                    con.setLocker(item, '01')
                                                });
                                            }, 300)
                                        }}

                                        />
                                    )
                                }
                            })}
                        </Map>
                        : null
                        // <Typography 
                        // sx={{
                        //     textAlign: 'left',
                        //     borderBottom: '1px dashed #000',
                        //     fontWeight: '400',
                        //     cursor: "pointer",
                        //     mt: 1,
                        //     mb: 1,
                        // }}
                        // onClick={() => {
                        //     this.setState({
                        //         ...this.state,
                        //         showMap: true,
                        //     });
                        //     console.log(this.state)
                            
                        // }}
                        // >
                        //     Изменить тастамат    
                        // </Typography>


                    }




                    <TextField
                        required
                        label="Во сколько вы оставите посылку"
                        name='dropTime'
                        value={this.state.form.dropTime}
                        onChange={this.handleChange}

                    />

                    <Typography variant="body" sx={{
                        mt: '1ch',
                        mb: '1ch',
                        width: '32ch',
                    }}>
                       Если находитесь у тастамата, позвоните <a href="tel:+77710009191" target="_blank">+7 771 000 91 91</a> для вложения 
                    </Typography>

                    <Typography variant="h4" sx={{
                        mt: '0.5ch',
                        mb: '1ch',

                    }}>
                        Получатель
                    </Typography>
                    <TextField
                        required
                        label="Имя получателя"
                        name='receiverName'
                        value={this.state.form.receiverName}
                        onChange={this.handleChange}

                    />
                    <InputMask
                        required
                        mask="+7(799) 999 99 99"
                        value={this.state.form.receiverPhone}
                        disabled={false}
                        maskChar=" "
                        onChange={this.handleChange}
                    >
                        {() => <TextField
                        required
                        label="Номер телефона получателя"
                        name='receiverPhone'
                        // value={this.state.form.phone}
                        
                    />}
                    </InputMask>

                    <FormControl
                    required
                     sx={{
                        m: 1,
                        width: '35ch'
                    }}>
                        <InputLabel id="receiverCityLabel">Город</InputLabel>
                        <Select
                            required
                            labelId="receiverCityLabel"
                            id="receiverCity"
                            name="receiverCity"
                            value={this.state.form.receiverCity}
                            label="receiverCity"
                            onChange={this.handleChange}
                            defaultValue={'Алматы'}
                        >
                            <MenuItem value={'Астана'}>Астана</MenuItem>
                            <MenuItem value={'Алматы'}>Алматы</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        required
                        label="Тастамат получателя"
                        name='receiverLocker'
                        value={this.state.form.receiverLocker}
                        onChange={this.handleChange}
                    />

                    
                    {
                        this.state.showMap2 ?
                        <Map 
                            state={{ 
                                center:  this.state.form.receiverCity === 'Астана' ? [51.128207,71.430411] : [43.248095, 76.913244 ], 
                                zoom: 11,
                            }} 
                            width='35ch'    
                            height='35ch' 
                        >
                            {this.state.lockers.map((item) => {
                                if(item.address.regionCode === (this.state.form.receiverCity === 'Астана' ? '01' : '02')){
                                    var div = document.createElement("div");
                                    var btn = document.createElement("button");
                                    btn.className = "tstmtWdgt__choose";
                                    btn.type = "button";
                                    btn.innerText = "Выбрать";
                                    btn.id = item.id;
                                    div.className = "tstmtWdgt__ballon-body";
                                    div.innerHTML = '<p class="id">Индекс - ' + item.index + '</p>' +
                                    '<p class="title">тастамат</p>'+
                                    '<p class="address">' + item.address.buildingShortRu + '</p>'+
                                    '<p class="street">' + item.address.streetShortRu + ', ' + item.address.buildingNumber + '</p>'+
                                    '<p class="desc">' + (item.location.descriptionRu ? item.location.descriptionRu : '') + '</p>' +
                                    '<p class="desc">' + (item.location.time ? ('Режим работы: ' + item.location.time) : '') + '</p>'
                                    div.appendChild(btn);
                                    
                                    return (
                                        <Placemark
                                        options={{preset: 'islands#circleIcon', iconColor: '#fd7e14'} }
                                        defaultGeometry={[item.location.latitude, item.location.longitude]}
                                        key={item.id}
                                        modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                        properties={{
                                            iconCaption: item.index,
                                            hintContent: item.index,
                                            balloonContentBody: div.outerHTML,
                                        }}
                                        onClick={() => {
                                            let con = this
                                            setTimeout(function () {
                                                document.getElementById(item.id).addEventListener('click', () => {
                                                    con.setLocker(item, '02')
                                                });
                                            }, 300)
                                        }}

                                        />
                                    )
                                }
                            })}
                        </Map>
                        : null
                        // <Typography 
                        // sx={{
                        //     textAlign: 'left',
                        //     borderBottom: '1px dashed #000',
                        //     fontWeight: '400',
                        //     cursor: "pointer",
                        //     mt: 1,
                        //     mb: 1,
                        // }}
                        // onClick={() => {
                        //     this.showMap('02')
                        // }}
                        // >
                        //     Изменить тастамат    
                        // </Typography>
                    }

                    
                    <FormControlLabel
                        name='checkbox'
                        value={this.state.form.check}
                        onChange={this.handleChange}
                        control={<Checkbox />}
                        label={(
                            <Typography component="span">
                            Я согласен с <a href="https://drive.google.com/file/d/1U4_v1lwVJyGPYRUjRYRjGtRJo0uDq_5V/view" target="_blank">публичной офертой</a>
                            </Typography>
                        )}
                        labelPlacement="end"
                        sx={{
                            width: '35ch'
                        }}
                    />

                    <FormControlLabel
                        name='checkbox1'
                        value={this.state.form.check1}
                        onChange={this.handleChange}
                        control={<Checkbox />}
                        label={(
                            <Typography component="span">
                            Подтверждаю, что не отправляю <a href="https://docs.google.com/document/d/1Sto16ZPkrq1HRQzvnj6cqLCBAHdmblx7jhbstMLdJuM/edit" target="_blank">опасные грузы</a>
                            </Typography>
                        )}
                        labelPlacement="end"
                        sx={{
                            width: '35ch'
                        }}
                    />

                    <Typography variant="h6" sx={{
                        mt: '1ch',
                        mb: '1ch',
                        width: '32ch',
                        fontWeight: "bold"
                    }}>
                        После успешной оплаты ожидайте звонка от оператора
                    </Typography>

                    <Button 
                        variant="contained"
                        sx={{
                            width: '45ch',
                            m: 2
                        }}
                        onClick={this.payment}

                        disabled={!(!this.state.form.check && this.state.form.receiverPhone.length > 0 && this.state.form.receiverName.length > 0 && this.state.form.receiverLocker.length > 0 && this.state.form.dropTime.length > 0 && this.state.form.locker.length > 0 && this.state.form.email.length > 0 && this.state.form.name.length > 0 && this.state.form.phone.length > 0)}
                    >
                        Оплатить
                    </Button>

                </Box>
            </YMaps>

            </Layout>
            </>
        );
    }
}

export default Intercity;
