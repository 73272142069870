import axios from "axios";

export function getSatuPaymentUrl(obj) {
    return axios.get('https://epay.tastamat.com/payment/pay', {
        params: obj
    });
}

export function getLockers(region) {

    return axios.get(`https://api.tastamat.com/platform/v1/rest/lockers?status=ON${region !== undefined ? '&region='+region : ''} `)
}