import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Home from "./pages/home";
import Lrt from "./pages/lrt";
import Intercity from './pages/intercity';
import OrderPay from './pages/orderPay';

let theme = createTheme({
  palette: {
    primary: {
      main: '#F05A28',
    },
    secondary: {
      main: '#898C8D',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/lrt" element={<Lrt />}/>
        {/* <Route path="/intercity" element={<Intercity />}/> */}
        <Route path="/order-pay" element={<OrderPay />}/>
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
