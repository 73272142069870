import * as React from 'react';
import Layout from "../layout";
import { 
    TextField,
    Box,
    Button,
    FormControlLabel,
    Checkbox,
    Typography
 } from '@mui/material';
 import InputMask from "react-input-mask";
import { getSatuPaymentUrl } from '../services/payment.service';

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                order: "",
                name: "",
                email: "",
                phone: "",
                check: false
            }
        };
    }

    getQueryParams = () => {
        const searchParams = new URLSearchParams(document.location.search)
        let order = searchParams.get('order')
        let name = searchParams.get('name')
        let email = searchParams.get('email')
        let phone = searchParams.get('phone')
        this.setState({
            form: {
                order: order !== null ? order : '',
                name: name !== null ? name : '',
                email: email !== null ? email : '',
                phone: phone !== null ? phone : '',
            }
        })
      }

    componentDidMount() {
        this.getQueryParams()
        console.log()
    }

    handleChange = e => {
        let { name, value, checked } = e.target;

        this.setState(
            {
                form: {
                    ...this.state.form,
                    [name]: name !== 'checkbox' ? value : checked
                }
            },
        );

        console.log(this.state.form[name]);
    }

    payment = () => {
        var phone = this.state.form.phone.replace(/[^0-9+]/g, '');
        var d = new Date().getTime()

        let obj = {
            invoiceId: d,
            amount: 1000,
            recipientName: this.state.form.name,
            recipientEmail: this.state.form.email,
            recipientPhone: phone,
            fromApp: 'satuApp',
            data: JSON.stringify({
                order: this.state.form.order
            })
        }

        getSatuPaymentUrl(obj)
            .then(res => {
                            
                console.log("SUCCESS");
                console.log(res.data);

                window.location.href = res.data.url;
            })
            .catch(err => {
                console.log(err)
            })
    }


    render() {
        return (
            <>
            <Layout>
                
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: '30px'
                    }}
                    noValidate
                    autoComplete="on"
                >
                    <Typography variant="h3" sx={{
                        textAlign: 'center'
                    }}>
                    Уважаемые клиенты!<br/>
                    Информируем вас, что услуга доставки более не предоставляется. Приносим извинения за возможные неудобства и благодарим за ваше понимание.
                    </Typography>
                    {/* <TextField
                        required
                        label="Номер заказа"
                        name='order'
                        value={this.state.form.order}
                        onChange={this.handleChange}

                    />
                    <TextField
                        required
                        label="Имя"
                        name='name'
                        value={this.state.form.name}
                        onChange={this.handleChange}

                    />
                    <TextField
                        required
                        label="Email"
                        name='email'
                        value={this.state.form.email}
                        onChange={this.handleChange}

                    />
                    <InputMask
                        mask="+7(799) 999 99 99"
                        value={this.state.form.phone}
                        disabled={false}
                        maskChar=" "
                        onChange={this.handleChange}
                    >
                        {() => <TextField
                        label="Номер телефона"
                        name='phone'
                        // value={this.state.form.phone}
                        
                    />}
                    </InputMask>
                            
                    <FormControlLabel
                        name='checkbox'
                        value={this.state.form.check}
                        onChange={this.handleChange}
                        control={<Checkbox />}
                        label={(
                            <Typography component="span">
                            Я согласен с <a href="https://drive.google.com/file/d/1U4_v1lwVJyGPYRUjRYRjGtRJo0uDq_5V/view" target="_blank">публичной офертой</a>
                            </Typography>
                        )}
                        labelPlacement="end"
                        sx={{
                            width: '25ch'
                        }}
                    />

                    <Button 
                        variant="contained"
                        sx={{
                            width: '35ch',
                            m: 2
                        }}
                        onClick={this.payment}

                        disabled={!(!this.state.form.check && this.state.form.email.length > 0 && this.state.form.name.length > 0 && this.state.form.phone.length > 0 && this.state.form.order.length > 0)}
                    >
                        Оплатить
                    </Button> */}

                </Box>
            </Layout>
            </>
        );
    }
}

export default Home;
