import * as React from 'react';
import Box from '@mui/material/Box'
import ResponsiveAppBar from './navbar';


const Layout =({children, sx}) =>{
    return(
        <>
            <ResponsiveAppBar/>
            <Box sx={sx}>
                {children}
            </Box>
        </>
    )
}

export default Layout;