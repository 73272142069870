import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

function ResponsiveAppBar() {

  return (
        <AppBar color='transparent' position="static" sx={{
            background: '#F05A28',
            boxShadow: 'none',
            borderBottom: "1px solid #D7E2E5",
            height: {
                md: 90,
                lg: 80,
                xl: 72
            }
        }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                }}>
                    <Typography
                        component="h1"
                        sx={{
                            color: 'white',
                            fontFamily: 'Montserrat, Arial, sans-serif',
                            fontWeight: 700,
                            fontSize: "24px"
                        }}
                    >
                        Оплата доставки
                    </Typography>


                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;