import * as React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import Layout from "../layout";
import { 
    TextField,
    Box,
    Button,
    FormControlLabel,
    Checkbox,
    Typography,
    Autocomplete
 } from '@mui/material';
 import InputMask from "react-input-mask";
import { getLockers, getSatuPaymentUrl } from '../services/payment.service';

class Lrt extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                order: "",
                name: "",
                email: "",
                phone: "",
                locker: "",
                check: false
            },
            lockers: [],
            options: [
                { label: 'Organise'},
                { label: 'Joha'},
                { label: 'Terminator'},
                { label: 'Dull'},
                { label: 'Nzaza'},
            ],
            showMap: true
        };
    }

    getQueryParams = () => {
        const searchParams = new URLSearchParams(document.location.search)
        let order = searchParams.get('order')
        let name = searchParams.get('name')
        let email = searchParams.get('email')
        let phone = searchParams.get('phone')
        let locker = searchParams.get('locker')

        this.setState({
            ...this.state,
            form: {
                order: order !== null ? order : '',
                name: name !== null ? name : '',
                email: email !== null ? email : '',
                phone: phone !== null ? phone : '',
                locker: locker !== null ? locker : '',
            }
          });
      }

    componentDidMount() {
        this.getQueryParams()
        // console.log()
        // const script = document.createElement("script");

        // script.src = "https://static.tastamat.kz/widget/script-1.1.0.js";
        // script.type = "text/javascript"

        // document.body.appendChild(script);

        getLockers('01')
            .then(res => {
                this.setState({
                    ...this.state,
                    lockers: res.data.list
                  });
                console.log(this.state)
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleChange = e => {
        let { name, value, checked } = e.target;

        this.setState(
            {
                form: {
                    ...this.state.form,
                    [name]: name !== 'checkbox' ? value : checked
                }
            },
        );

        console.log(this.state.form[name]);
    }

    setLocker = (e) => {
        console.log("SET_LOCKER")
        console.log(e)
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                locker: `Индекс ${e.index} - ${e.address.buildingShortRu}`
            },
            showMap: false,
        });
    }

    payment = () => {
        var phone = this.state.form.phone.replace(/[^0-9+]/g, '');
        var d = new Date().getTime()

        let obj = {
            invoiceId: d,
            amount: 1000,
            recipientName: this.state.form.name,
            recipientEmail: this.state.form.email,
            recipientPhone: phone,
            data: JSON.stringify({
                order: this.state.form.order,
                lockerId: this.state.form.locker,
            }),
            fromApp: 'lrtApp'
        }

        getSatuPaymentUrl(obj)
            .then(res => {
                            
                console.log("SUCCESS");
                // console.log(res.data);

                window.location.href = res.data.url;
            })
            .catch(err => {
                console.log(err)
            })
    }


    render() {
        return (
            <>
            <Layout>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '35ch' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: '30px'
                    }}
                    noValidate
                    autoComplete="on"
                >
                    <TextField
                        required
                        label="Номер заказа"
                        name='order'
                        value={this.state.form.order}
                        onChange={this.handleChange}

                    />
                    <TextField
                        required
                        label="Имя"
                        name='name'
                        value={this.state.form.name}
                        onChange={this.handleChange}

                    />
                    <TextField
                        required
                        label="Email"
                        name='email'
                        value={this.state.form.email}
                        onChange={this.handleChange}

                    />
                    <InputMask
                        required
                        mask="+7(799) 999 99 99"
                        value={this.state.form.phone}
                        disabled={false}
                        maskChar=" "
                        onChange={this.handleChange}
                    >
                        {() => <TextField
                        required
                        label="Номер телефона"
                        name='phone'
                        // value={this.state.form.phone}
                        
                    />}
                    </InputMask>
                    <TextField
                        required
                        label="Тастамат"
                        name='locker'
                        value={this.state.form.locker}
                        onChange={this.handleChange}

                    />

                    <YMaps>
                        {
                            this.state.showMap ?
                            <Map 
                                defaultState={{ 
                                    center: [51.128207,71.430411], 
                                    zoom: 12,
                                }} 
                                width='35ch'    
                                height='35ch' 
                            >
                                {this.state.lockers.map((item) => {
                                    var div = document.createElement("div");
                                    var btn = document.createElement("button");
                                    btn.className = "tstmtWdgt__choose";
                                    btn.type = "button";
                                    btn.innerText = "Выбрать";
                                    btn.id = item.id;
                                    div.className = "tstmtWdgt__ballon-body";
                                    div.innerHTML = '<p class="id">Индекс - ' + item.index + '</p>' +
                                    '<p class="title">тастамат</p>'+
                                    '<p class="address">' + item.address.buildingShortRu + '</p>'+
                                    '<p class="street">' + item.address.streetShortRu + ', ' + item.address.buildingNumber + '</p>'+
                                    '<p class="desc">' + (item.location.descriptionRu ? item.location.descriptionRu : '') + '</p>' +
                                    '<p class="desc">' + (item.location.time ? ('Режим работы: ' + item.location.time) : '') + '</p>'
                                    div.appendChild(btn);
                                    


                                    return (
                                        <Placemark
                                        options={{preset: 'islands#circleIcon', iconColor: '#fd7e14'} }
                                        defaultGeometry={[item.location.latitude, item.location.longitude]}
                                        key={item.id}
                                        modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                        properties={{
                                            iconCaption: item.index,
                                            hintContent: item.index,
                                            balloonContentBody: div.outerHTML,
                                        }}
                                        onClick={() => {
                                            let con = this
                                            setTimeout(function () {
                                                document.getElementById(item.id).addEventListener('click', () => {
                                                    con.setLocker(item)
                                                });
                                            }, 300)
                                        }}

                                        />
                                    )
                                })}
                            </Map>
                            : null
                        }

                    </YMaps>

                    <Typography variant="body" sx={{
                        width: '35ch',
                        mt: '1ch',
                        mb: '1ch',
                    }}>
                        Карта изготавливается 5 рабочих дней.<br></br>
                        По вопросам изготовления карты обращайтесь по номеру - 109.
                    </Typography>
                    
                    <FormControlLabel
                        name='checkbox'
                        value={this.state.form.check}
                        onChange={this.handleChange}
                        control={<Checkbox />}
                        label={(
                            <Typography component="span">
                            Я согласен с <a href="https://drive.google.com/file/d/1U4_v1lwVJyGPYRUjRYRjGtRJo0uDq_5V/view" target="_blank">публичной офертой</a>
                            </Typography>
                        )}
                        labelPlacement="end"
                        sx={{
                            width: '35ch'
                        }}
                    />

                    <Button 
                        variant="contained"
                        sx={{
                            width: '45ch',
                            m: 2
                        }}
                        onClick={this.payment}

                        disabled={!(!this.state.form.check && this.state.form.locker.length > 0 && this.state.form.email.length > 0 && this.state.form.name.length > 0 && this.state.form.phone.length > 0 && this.state.form.order.length > 0)}
                    >
                        Оплатить
                    </Button>

                </Box>
            </Layout>
            </>
        );
    }
}

export default Lrt;
